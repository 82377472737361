import { FC } from 'react';

import { ContentSectionProps } from '../types';

const NPLSearchDropdownContent: FC<ContentSectionProps> = ({
  eyebrow,
  label,
  chin
}) => {
  return (
    <div className="flex flex-grow flex-col items-stretch w-full truncate">
      {eyebrow && (
        <span className="text-label-sm text-npl-text-secondary-on-light mb-2 overflow-ellipsis">
          {eyebrow}
        </span>
      )}
      <span className="text-label-lg text-npl-text-primary-on-light overflow-ellipsis whitespace-nowrap truncate">
        {label}
      </span>
      {chin && (
        <span className="text-label-sm text-npl-text-secondary-on-light overflow-ellipsis mt-2">
          {chin}
        </span>
      )}
    </div>
  );
};

export default NPLSearchDropdownContent;
