import type { Placement } from '@popperjs/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

import { useOutsideComponentStateClickDetect } from '@/hooks/useOutsideComponentClickDetect';

type PopoverProps = WithComponentClassName<{
  children: React.ReactNode;
  content: (onClose: () => void) => React.ReactNode;
  placement?: Placement;
}>;

const Popover: React.FC<PopoverProps> = ({
  componentClassName,
  children,
  content,
  placement = 'top-start'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement
    }
  );

  const handleOnClose = () => {
    setTimeout(() => {
      setIsOpen(false);
    });
  };

  useOutsideComponentStateClickDetect(popperElement, handleOnClose);

  return (
    <div
      className={classNames(componentClassName, 'relative flex')}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(true);
      }}
      ref={setReferenceElement}>
      {children}
      {isOpen &&
        createPortal(
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-over-intercom">
            <div className="bg-white-default rounded-16 shadow-npl-styles-shadow-02">
              {content(handleOnClose)}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default withComponentClassName(Popover, false);
